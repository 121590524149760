import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`query {
      site {
        siteMetadata {
          author
        }
      }
    }`
  )

  return (
  <div style={{margin: '10px', textAlign: 'center'}}>
    &copy; {new Date().getFullYear()} {site.siteMetadata.author}, All Rights Reserved
  </div>
  )
}

export default Footer
