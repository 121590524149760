import React from "react"
import "./page.scss"
import Footer from "./footer"

const Page = ({ children, context }) => {
  return (
    <div className="page-container">
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Page
